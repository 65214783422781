import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Main',
    component: () => import('../views/MainView.vue')
  },
  {
    path: '/index',
    name: 'index',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/IndexView.vue')
  },
  {
    path: '/video',
    name: 'video',
    component: () => import('../views/VideoView.vue')
  },
  {
    path:'/user',
    name:'user',
    component: () => import('../views/UserView.vue')
  },
  {
    path:'/search',
    name:'search',
    component:() => import('../views/SearchView.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
